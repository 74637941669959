export const message = {

  tab_home: 'Home',
  tab_exchange: 'Exchange',
  tab_options: 'Options',
  tab_contracts: 'Contracts',
  tab_start: 'Trade',
  tab_records: 'Records',
  tab_profile: 'Profile',
  copy_ok: 'Copied',
  copy_btn: 'COPY',
  submit: 'SUBMIT',
  all_btn: 'All',
  customer_service:'Customer Service',
  my_contact: 'Contact Us',
  contact_us_tips_title: 'Notice',
  contact_us_tips: 'Click To Contact Customer Service',
  qd_desc_info_1: 'Working time: ',
  qd_desc_info_2: 'For recharge and withdrawals, please contact our Customer Service',

  login_from_third: 'Or use the following way to log in',
  google: 'Google',
  facebook: 'Facebook',
  sign_in_apple: 'Sign In With Apple',
  allow_face_id: 'Allow FaceID',
  loading: 'Loading...',

  confirm_btn:'Confirm',
  cancel_btn:'Cancel',
  sign_in_success: 'Sign in Successfully',
  opration_success: 'The operation was successful.',

  my_logout: 'Are you sure you want to log out account?',

  login: 'Sign in',
  forgot: 'Forgot?',

  login_phone_title: 'Phone Number/User Name',
  login_pass_title: 'Password',

  profile_all_money: 'Asset Center(USDT)',
  invite_code: 'Invite code:',
  profile_recharge:'Recharge',
  profile_withdrawal:'Withdrawal',
  profile_transfer:'Transfer',

  profile_exchange_account:'Exchange account',
  profile_contract_account:'Contract account',
  profile_option_account:'Option account',

  profile_customer_service: 'Customer Service',
  action_security_title: 'Security',
  profile_notification: 'Notifications',
  profile_invite: 'Invitation',

  profile_logout: 'Logout',

  withdrawal_pwd_title: 'Withdrawal Password',
  withdrawal_amount_err: 'Error in withdrawal amount',
  login_name: 'Please input your username/Phone NO.',
  login_name_title: 'Username',
  login_qr: 'Please enter the invitation code',
  login_qr_title: 'Invitation Code',
  login_passnot: 'Confirm password does not match',

  reg_ok: 'Successful registration',
  reg_title: 'Register',
  ret_no_name: 'Please input your username',
  ret_no_tel: 'Please input your Phone NO.',
  zcxy: 'Register Agreement',
  edit_fail: 'Modification failed',

  index_reg_title: 'Register Now',
  index_login_title: 'Login',

  register_title: 'Register',
  register_to_login: 'Back To Login',
  bind_bank_tel: 'Phone number',

  edit_pass: 'Login Password',
  with_money_sub_title: 'Withdrawal Password',
  please_newpass: 'Please enter your new password',

  old_pass: 'Old password',
  new_pass: 'New password',
  old_withdrawal_pass: 'Old withdrawal password',
  new_withdrawal_pass: 'New withdrawal password',
  qu_newpass: 'Confirm password',
  qu_new_withdrawal_pass: 'Confirm withdrawal password',

  q_dl_mim: 'Please enter the login password',
  msg_title: 'Message',
  msg_list_title: 'Message List',

  invite_tittle: 'Invitation',
  my_tgcode: 'My Referral Code',

  home_recharge_btn:'Recharge/Loan/Withdrawal',
  home_market_quote:'Market quotation',
  cp_my_tgcode: 'Copy Referral Code',

  home_market_quote_pair:'Pair',
  home_market_quote_price:'Price',
  home_market_quote_change:'Change',

  home_market_quote_no_more:'',//'There\'s no more.',
  home_market_quote_no_data:'No data',
  home_market_quote_order_title: 'Market trades',
  home_market_quote_order_title_time: 'Time',
  home_market_quote_order_title_direct: 'Direction',
  home_market_quote_order_title_price: 'Price',
  home_market_quote_order_title_number: 'Number',
  home_market_quote_order_direct1: 'Inbuy',
  home_market_quote_order_direct2: 'Outsell',

  home_market_quote_high:'High',
  home_market_quote_low:'Low',
  home_market_quote_quantity:'24H quantity',

  home_desc_title:'World leading.One stop digital asset trading platform',

  home_desc_subtitle1:'Security assurance',
  home_desc_sub_value1:'A number of top security agencies work together to ensure safe and efficient user transactions',

  home_desc_subtitle2:'7x24 hours customer service',
  home_desc_sub_value2:'7x24 hours online customer service, ready to respond to your needs',

  home_desc_subtitle3:'Globalization coverage',
  home_desc_sub_value3:'International community , covering 205 countries and regions worldwide, supporting 19 countries and region, and 5 million registered users',

  exchange_left_buy:'BUY',
  exchange_left_sell:'SELL',

  exchange_left_mode_title:'Transaction mode',
  exchange_left_mode_opt1:'Market',
  exchange_left_mode_opr2:'Limit',

  exchange_left_price_title:'Price',
  exchange_left_price_placeholder:'Pls input price',
  exchange_left_price_market:'Market optimal price',

  exchange_left_number_title:'Number',
  exchange_left_number_placeholder:'Pls input number',

  exchange_left_usable:'Usable',
  exchange_left_volume:'Volume',

  exchange_history_title1:'Current Entrust',
  exchange_history_title2:'History Entrust',


  options_left_buy:'LONG',
  options_left_sell:'SHORT',

  options_left_mode_title:'Transaction mode',
  options_left_mode_opt1:'USDT',

  options_left_price_title:'Opening quantity',
  options_left_price_placeholder:'',
  options_left_price_market:'Market optimal price',

  options_left_time_title:'Open time',
  options_left_time1:'120 Sec',
  options_left_time2:'180 Sec',
  options_left_time3:'320 Sec',

  options_left_usable:'Usable',
  options_left_profit:'Profit rate',

  options_depth_price:'Unit Price',
  options_depth_vol:'Number',

  options_order_list_title1:'Transaction',
  options_order_list_title2:'Closed',

  options_order_list_pair:'Pair',
  options_order_list_dir:'Direction',
  options_order_list_op:'Opening quantity',
  options_order_list_pp:'Purchase price',
  options_order_list_cp:'Current price',
  options_order_list_el:'Estimate profit or loss',
  options_order_list_cd:'Count down',

  options_order_list_tp:'Transaction price',
  options_order_list_fee:'Fee',
  options_order_list_pl:'Profit or loss',


  contracts_left_buy:'LONG',
  contracts_left_sell:'SHORT',

  contracts_left_mode_title:'Transaction mode',
  contracts_left_mode_opt1:'Market',
  contracts_left_mode_opt2:'Limit',

  contracts_left_price_title:'Price',
  contracts_left_number_title:'Number',

  contracts_left_leverage_title:'Leverage',
  contracts_left_amount_title:'Amount',
  contracts_left_amount_unit:'Hand',

  contracts_left_usable:'Usable',
  contracts_left_total:'Total',
  contracts_left_bond:'Bond',
  contracts_left_fee:'Fee',

  contracts_order_title1:'Entrust',
  contracts_order_title2:'Position',
  contracts_order_title3:'Closed',
  contracts_order_title4:'Revoked',

  recharge_currency_opt1:'USDT',
  wallet_erc20:'ERC 20',
  wallet_trc20:'TRC 20',

  recharge_address_title: 'Recharge address',
  recharge_quantity_title: 'Recharge quantity',

  withdrawal_currency_opt1:'USDT',

  withdrawal_extractable_quantity_title: 'Extractable quantity',
  withdrawal_address_title: 'Please enter the withdrawal address',
  withdrawal_address_tips: 'The coin pick-up address should not contain spaces',
  withdrawal_amount_title: 'Amount of coin drawn',
  withdrawal_fee: 'Fee ',
  withdrawal_amount_tps: 'Arrived amount ',


  transfer_currency_title: 'Select currency',
  transfer_currency_opt1:'USDT',
  transfer_account_opt1:'Base Account(Withdrawable)',
  transfer_account_opt2:'Contracts Account',
  transfer_account_opt3:'Options Account',
  transfer_account_opt4:'Exchange Account',
  transfer_from_account: 'From',
  transfer_to_account: 'To',
  transfer_amount_title: 'Transfer number',
  transfer_usable: 'Usable ',

  account_unit_opt1:'USDT',
  account_available_title:'Available',
  account_progress_title:'In Progress',

  error_paramter:'Error parameter',

  options_amount_low:'The quantity cannot be less than ',

  options_lost: 'Sorry, you lost.',
  options_won: 'Congratulations. You won.',
  system_busy: 'System is busy'

}
