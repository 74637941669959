<template>
  <div class="item_context page_flex page_flex_row" @click="on_click">
    <div class="symbol_name_block page_flex page_flex_row">
      <div class="left">
        <img :src="require('@/assets/logos/' + symbol_target.toLowerCase() + '.svg')">
      </div>
      <div class="right">
        <div class="target" v-html="symbol_target"></div>
        <div class="base" v-html="symbol_base"></div>
      </div>
    </div>
    <div class="symbol_price_block">
      <div :class="price_class" v-html="price"></div>
      <div class="down" v-html="vol+'K'"></div>
    </div>
    <div style="width: 25%;" :class="change_class" v-html="change+'%'">
    </div>
  </div>

</template>

<script>
export default {
  props: {
    symbol_target: {
      type: String,
      default: ''
    },
    symbol_base: {
      type: String,
      default: ''
    },
    price: {
      type: Number,
      default: 0
    },
    vol: {
      type: Number,
      default: 0
    },
    change: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      price_class: 'white',
      change_class: 'white',
    }
  },
  watch: {
    price(newvar,oldvar){
      this.priceChange(newvar,oldvar);
    },
    change(newvar,oldvar){
      this.percentChange(newvar);
    }
  },

  methods: {
    priceChange(value_new,value_old){
      const num_new = parseFloat(value_new);
      const num_old = parseFloat(value_old);
      if(num_new < num_old){
        this.price_class = 'red';
      }
      else if(num_new > num_old) {
        this.price_class = 'green';
      }
      else {
        this.price_class = 'white';
      }
    },
    percentChange(value){
      const num = parseFloat(value);
      if(num < 0.000000000){
        this.change_class = 'change_positive';
      }
      else if(num > 0.000000000) {
        this.change_class = 'change_negative';
      }
      else {
        this.change_class = 'change_zero';
      }
    },
    on_click(event){
      // console.log('item_quote:',event);
      this.$emit('click', event);
    }
  },
  mounted() {
    //console.log('text mounted....:',this.text_num);
    const num = this.price;
    const num2 = this.change;
    if (num2 > 0.000000000){
      this.price_class = 'green';
      //console.log('text mounted....red');
    }
    else if (num2 < 0.000000000) {
      this.price_class = 'red';
      //console.log('text mounted....green');
    }
    else {
      this.price_class = 'white';
      //console.log('text mounted....white');
    }


    if (num2 > 0.000000000){
      this.change_class = 'change_positive';
      //console.log('text mounted....red');
    }
    else if (num2 < 0.000000000) {
      this.change_class = 'change_negative';
      //console.log('text mounted....green');
    }
    else {
      this.change_class = 'change_zero';
      //console.log('text mounted....white');
    }

    //console.log('text mounted....4');
  }
}
</script>

<style lang="less" scoped>
@import '@/css/pagestyle.less';

.item_context{
  background: rgba(0,0,0,0);
  text-align: center;

  .symbol_name_block{
    width: 25%;

    .left{
      .page_flex();
      .page_flex_column();
      .page_flex_center();

      img{
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background: white;
      }
    }

    .right{
      padding-left: 10px;
      text-align: left;
      .target{

      }

      .base{
        padding-top: 5px;
        color: #888888;
      }
    }
  }

  .symbol_price_block{
    width: 50%;

    .up{

    }

    .down{
      padding-top: 5px;
      color: #888888;
    }
  }

}

.green{
  color: #22aa66;
}

.red{
  color: #ff2222;
}

.black{
  color: black;
}

.white{
  color: white;
}

.change_negative{
  width: 100%;
  height: 100%;
  background: #ff2222;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 7px;
}

.change_positive{
  width: 100%;
  height: 100%;
  background: #22aa66;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 7px;
}

.change_zero{
  width: 100%;
  height: 100%;
  background: #555555;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 7px;
}

</style>
