<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="$t('message.zcxy')" left-arrow @click-left="$router.go(-1)" fixed/>
    <div class="second_page_margin" v-html="html_content">
    </div>
  </div>
</template>

<script>
import { getConfigPage,getRegisterNote } from '@/api/conf';

export default {
  data() {
    return {
      mytitle: '',

      html_content:'',
    }
  },
  name: 'ConfPage',

  methods: {
  },
  async created() {

    const note_page = await getRegisterNote();
    if(note_page.code == 0){
      this.html_content = note_page.config_content;
    }
  },
  destroyed() {
  },
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';
</style>
