export const message = {
  my: '我的',
  // 格式化
  icon: '图标{0}',
  home: '首页',
  search: '查找',
  friends: '朋友',
  setting: '设置',
  back: '返回',
  confirm: '确定',
  cancel: '取消',
  languages: '语言'
}