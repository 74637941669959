import {getInfo, setInfo} from "@/utils/storage"



export default {
  namespaced: true,
  state() {
    return {
      userInfo: getInfo()
    }
  },

  mutations: {
    setUserInfo(state, obj) {
      state.userInfo = obj
      setInfo(obj)
    },
  },
  actions: {
    async logout(context) {
      console.log('logout 99');
      context.commit('setUserInfo', {})
      context.commit('symbol/setSymbolInfo',{}, { root: true })
    }
  },
  getters: {
    token(state){
      return state.userInfo.token;
    },
    target(state){
      return state.userInfo.target;
    },
  },

}
