import {getSymbolInfo, setSymbolInfo} from "@/utils/storage"

export default {
  namespaced: true,

  state(){
    // console.log('user.symbol');
    return{
      symbolInfo:getSymbolInfo()
    }
  },

  mutations: {
    setSymbolInfo(state, obj){
      console.log('setSymbolInfo--------------------------');
      state.symbolInfo = obj;
      setSymbolInfo(obj);
    }
  },
  actions: {},
  getters: {
    symbolInfo(state){
      // console.log('storage.symbolInfo',state);
      return state.symbolInfo;
    }
  },
}
