<template>

  <div class="">
    <div :class="item_class">
      <div class="time" v-html="time"></div>
      <div class="direct" v-html="$t('message.home_market_quote_order_direct1')" v-if="type > 1"></div>
      <div class="direct" v-html="$t('message.home_market_quote_order_direct2')" v-if="type <= 1"></div>
      <div class="price" v-html="price.toFixed(4)"></div>
      <div class="vol" v-html="vol.toFixed(6)"></div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    time:{
      type:String,
      default:''
    },
    price: {
      type: Number,
      default: 0
    },
    vol: {
      type: Number,
      default: 0
    },
    type: {
      color_type: Number,
      default: 1
    }
  },
  data: function () {
    return {
      direct_tex: '',
      item_class: '',
    }
  },
  watch: {
    type(newvar,oldvar){
      this.directChange(newvar,oldvar);
    }
  },

  methods: {
    directChange(new_value,old_value){
      if (new_value > 1){
        this.direct_tex = this.$t('message.home_market_quote_order_direct1');
        this.item_class = 'item_context_buy';
      }
      else {
        this.direct_tex = this.$t('message.home_market_quote_order_direct2');
        this.item_class = 'item_context_sell';
      }
    }
  },
  mounted() {
    if (this.type > 1){
      this.direct_tex = this.$t('message.home_market_quote_order_direct1');
      this.item_class = 'item_context_buy';
    }
    else {
      this.direct_tex = this.$t('message.home_market_quote_order_direct2');
      this.item_class = 'item_context_sell';
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/css/pagestyle.less';

.green{
  color: #22aa66;
}

.red{
  color: #ff2222;
}

.item_context_sell{
  .page_flex();
  .page_flex_row();
  padding: 5px 0;
  .green();

  .time{
    width: 20%;
    text-align: left;
  }

  .direct{
    width: 20%;
    text-align: center;
  }

  .price{
    width: 30%;
    text-align: center;
  }

  .vol{
    width: 30%;
    text-align: right;
  }
}

.item_context_buy{
  .page_flex();
  .page_flex_row();
  padding: 5px 0;
  .red();

  .time{
    width: 20%;
    text-align: left;
  }

  .direct{
    width: 20%;
    text-align: center;
  }

  .price{
    width: 30%;
    text-align: center;
  }

  .vol{
    width: 30%;
    text-align: right;
  }
}


</style>
