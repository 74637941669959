<template>
  <span style="padding: 5px;" :class="style_num" v-html="text_num.toFixed(5)">
  </span>
</template>

<script>
export default {
  props: {
    text_num: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      style_num: 'black'
    }
  },
  watch: {
    text_num(newvar,oldvar){
      this.handleChange(newvar);
    }
  },

  methods: {
    handleChange(value){
      const num = value;//parseFloat(value);
      if(num < 0.000000000){
        this.style_num = 'red';
      }
      else if(num > 0.000000000) {
        this.style_num = 'green';
      }
      else {
        this.style_num = 'black';
      }
    }
  },
  mounted() {
    //console.log('text mounted....:',this.text_num);
    const num = this.text_num;//parseFloat(this.text_num);
    if (num > 0.000000000){
      this.style_num = 'green';
      //console.log('text mounted....red');
    }
    else if (num < 0.000000000) {
      this.style_num = 'red';
      //console.log('text mounted....green');
    }
    else {
      this.style_num = 'black';
      //console.log('text mounted....black');
    }
    //console.log('text mounted....4');
  }
}
</script>

<style lang="less" scoped>

.green{
  color: #22aa66;
}

.red{
  color: #ff2222;
}

.black{
  color: black;
}

</style>
