import { render, staticRenderFns } from "./DepthItem.vue?vue&type=template&id=4f20440e&scoped=true"
import script from "./DepthItem.vue?vue&type=script&lang=js"
export * from "./DepthItem.vue?vue&type=script&lang=js"
import style0 from "./DepthItem.vue?vue&type=style&index=0&id=4f20440e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f20440e",
  null
  
)

export default component.exports