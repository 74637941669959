import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import symbol from './modules/symbol'


Vue.use(Vuex)

export default new Vuex.Store({
  // state: {
  // },
  // getters: {
  //   token(state){
  //     return state.user.userInfo.token;
  //   },
  //   target(state){
  //     return state.user.userInfo.target;
  //   },
  //   symbolInfo(state){
  //     // console.log('storage.symbolInfo',state);
  //     return state.symbol.symbolInfo;
  //   }
  // },
  // mutations: {
  // },
  // actions: {
  // },
  modules: {
    user:user,
    symbol:symbol
  }
})
