import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vant from 'vant'
import 'vant/lib/index.css'
import { i18n } from './i18n/index'
import { Toast } from 'vant';

import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import { DropdownMenu, DropdownItem } from 'vant'
import 'default-passive-events'

Vue.use(DropdownMenu).use(DropdownItem);

Vue.use(Toast)
Vue.use(Vant)
Vue.config.productionTip = false

Vue.use(Chartkick.use(Chart))

console.log('aa:'+process.env.NODE_ENV);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
