const INFO_KEY = "user_info"
const SYMBOL_KEY = "symbol_info"
// const HISTORY_KEY = "history_key"

export const getInfo = () => {
    const defaultObj = { token: '', uid: '' ,target:''}
    const result = localStorage.getItem(INFO_KEY)
    return result ? JSON.parse(result) : defaultObj
}
export const setInfo = (obj) => {
    localStorage.setItem(INFO_KEY, JSON.stringify(obj))

}
export const removeInfo = () => {
    localStorage.removeItem(INFO_KEY)
}

export const getSymbolInfo = () => {
  const defaultObj = { target: 'BTC', base: 'USDT' ,direct:1};//direct:1sell,2buy
  const result = localStorage.getItem(SYMBOL_KEY)
  return result ? JSON.parse(result) : defaultObj
}
export const setSymbolInfo = (obj) => {
  localStorage.setItem(SYMBOL_KEY, JSON.stringify(obj))
}
export const removeSymbolInfo = () => {
  localStorage.removeItem(SYMBOL_KEY)
}

// export const getHistoryList = () => {
//     const result = localStorage.getItem(HISTORY_KEY)
//     return result ? JSON.parse(result) : []
// }
//
// export const setHistoryList = (arr) => {
//     localStorage.setItem(HISTORY_KEY, JSON.stringify(arr))
// }

