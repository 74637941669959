<template>

  <div class="">
    <div class="item_context red page_flex page_flex_row"
         :style="'background: linear-gradient(to right, #00000000 ' + left_percent + '%, #ff222250 ' + right_percent + '%);'"
    v-if="type>1">
      <div class="price" v-html="price.toFixed(4)"></div>
      <div class="vol" v-html="vol.toFixed(4)"></div>
    </div>

    <div class="item_context green page_flex page_flex_row"
         :style="'background: linear-gradient(to right, #00000000 ' + left_percent + '%, #22aa6650 ' + right_percent + '%);'" v-else>
      <div class="price" v-html="price.toFixed(4)"></div>
      <div class="vol" v-html="vol.toFixed(4)"></div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    price: {
      type: Number,
      default: 0
    },
    vol: {
      type: Number,
      default: 0
    },
    type: {
      color_type: Number,
      default: 1
    }
  },
  data: function () {
    return {
      left_percent: 10,
      right_percent: 90,
    }
  },
  watch: {
    vol(newvar,oldvar){
      this.volChange(newvar,oldvar);
    },
    price(newvar,oldvar){
      this.priceChange(newvar,oldvar);
    }
  },

  methods: {
    priceChange(new_value,old_value){
    },
    volChange(new_value,old_value){
      if (old_value > 0){
        let percent = new_value/old_value;
        if (percent > 1){
          percent = 1;
        }
        this.left_percent = percent * 100;
        this.right_percent = (1 - percent) * 100;
        if (this.right_percent < 10){
          this.left_percent = 90;
          this.right_percent = 10;
        }
      }
      else {
        this.left_percent = 90;
        this.right_percent = 10;
      }
    }
  },
  mounted() {
    this.left_percent = 90;
    this.right_percent = 10;
  }
}
</script>

<style lang="less" scoped>
@import '@/css/pagestyle.less';

.item_context{
  padding: 5px;

  .price{
    width: 50%;
    text-align: left;
  }

  .vol{
    width: 50%;
    text-align: right;
  }
}

.green{
  color: #22aa66;
}

.red{
  color: #ff2222;
}

</style>
