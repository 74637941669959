import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login'
import Layout from '@/views/layout'

import Home from '@/views/layout/home'
import Exchange from '@/views/layout/exchange'
import Options from '@/views/layout/options'
import Contracts from '@/views/layout/contracts'
import User from '@/views/layout/user'
import store from '@/store'
import ConfPage from '@/views/confpage/confpage'
import CustomerService from '@/views/customer/customer_service'
import Recharge from '@/views/finance/recharge'
import Withdrawal from '@/views/finance/withdrawal'
import Transfer from '@/views/finance/transfer'
import Account from '@/views/finance/account'
import RegisterNote from "@/views/confpage/register_note"
import Security from "@/views/customer/security"
import MessageList from "@/views/customer/message_list"
import MessageContent from "@/views/customer/message_content"
import Invite from "@/views/customer/invite"
import Quote from "@/views/quote/index"

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [

    {
      path: '/',
      redirect: '/home',
      component: Layout,
      children: [
        { path: 'home', component: Home },
        { path: 'exchange', component: Exchange },
        { path: 'options', component: Options },
        { path: 'contracts', component: Contracts },
        { path: 'user', component: User },
      ]
    },
    { path: '/confPage', component: ConfPage },
    { path: '/customer_service', component: CustomerService },
    { path: '/recharge', component: Recharge },
    { path: '/withdrawal', component: Withdrawal },
    { path: '/transfer', component: Transfer },
    { path: '/account', component: Account },
    { path: '/register_note', component: RegisterNote },
    { path: '/security', component: Security },
    { path: '/message_list', component: MessageList },
    { path: '/message_content', component: MessageContent },
    { path: '/invite', component: Invite },
    { path: '/quote', component: Quote },
    { path: '/login', component: Login },
  ]
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location, onResolve, onReject) {

  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)

  return originalPush.call(this, location).catch(err => err)

}


const loginUrl = '/login';
const unlogin_page = ['/','/home','/customer_service','/register_note','/login']

router.beforeEach((to, from, next) => {
  const token = store.getters['user/token'];
  // console.log('router(login):',token);

  //跳转后回到页面顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;

  console.log('to page:',to);

  if(!token){
    console.log('no token..');
    if(unlogin_page.includes(to.path)){
      console.log('unlogin_page..');
      next();
    }
    else{
      console.log('to login page..');
      next('/login');
    }
  }
  else{
    console.log('has token..');
    if(loginUrl == to.path){
      console.log('to home..');
      next('/');
    }else{
      console.log('to other..');
      next();
    }
  }
})

export default router
