<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="mytitle" left-arrow @click-left="$router.go(-1)" fixed />
    <div class="second_page_margin" v-html="html_content">
    </div>
  </div>
</template>

<script>
import request from '@/utils/requests';
import { watch } from 'vue';

export default {
    data() {
      return {
        mytitle: this.$t('message.zcxy'),
        html_content:'',
      }
    },
    name: 'RegisterNote',

    methods: {
    },
    async created() {
    },
    destroyed() {
        clearInterval(this.timer)
    },
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';
</style>
