/* 封装axios用于发送请求 */
import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'
import { i18n } from '../i18n/index'
// 创建一个新的axios实例
const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 5000
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // let that = this;
  config.params = {
    ...config.params,
    lang: i18n.locale // 添加语言参数
  }

  if(config.data.loadding){
    Toast.loading({
        message: '' + i18n.t('message.loading'),
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
    });
  }

  const token = store.getters['user/token']
  const target = store.getters['user/target']

  // console.log('request,token:',token);
  // console.log('request,target:',target);

  if (token && target) {
      config.headers['authorization'] = token
      config.headers['target'] = target
  }

  // 在发送请求之前做些什么
  return config
}, function (error) {
    console.log('request error:',error);
    Toast.clear();
    if(error.code == 'ECONNABORTED'){//timeout
      Toast(i18n.t('message.timeout'));
    }
    else{
      // other error
      Toast(error.message);
      return Promise.reject(error)
    }
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    console.log(response.status)
    Toast.clear()
    let res = response.data
    if(res){
      const res_json_str = JSON.stringify(res);
      if(res_json_str && res_json_str != undefined && res_json_str.trim() != ''){
        const res_json = JSON.parse(res_json_str);
        console.log('res_json:', res_json);

        if(res_json.code == 99){//登陆失效
          console.log('request 99');
          store.commit('user/setUserInfo',{});
          window.location.href = '/login';
        }

        return res_json;
      }
    }

    var json_err = "{'code':100}";
    return JSON.parse(json_err)



    // if (res.status != 200) {

    //     Toast(res.message)
    //     return Promise.reject(res.message)
    // } else {
    //     Toast.clear()
    // }
    // 对响应数据做点什么



}, function (error) {
    Toast.clear();
    console.log('response error:',error);
    if(error.code == 'ECONNABORTED'){//timeout
      Toast(i18n.t('message.timeout'));
    }
    else{
      // other error
      Toast(error.message);
    }
    console.log('response error 2');
    return Promise.reject(error)
})

export default request
